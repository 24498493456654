import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { catchError, finalize } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { BillingHistoryService } from '../services/billing-history.service';
import { ChargeCreditCardComponent } from './charge-credit-card/charge-credit-card.component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { of, Subject, Subscription } from 'rxjs';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import * as FileSaver from 'file-saver';
import { PaginationInstance } from 'ngx-pagination';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { Customer } from '../../shared/models/customer/customer.model';
import { SiteConfig } from '../../../config/site-config';
import { UserRouteService } from '../../core/services/user-routes/user-routes.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'upsc-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss'],
})
export class BillingHistoryComponent implements OnInit, OnDestroy {
  public user: User;
  public customer: Customer;
  public isUserEU = false;
  public isHKUser = false;
  public showManageCreditCard = false;
  public currencyCode: string;
  public searchFormGroup: UntypedFormGroup;
  public customDateRangeFormGroup: UntypedFormGroup;
  public defaultStartDate = moment().subtract(730, 'days').startOf('day').toDate();
  public defaultEndDate = moment().toDate();
  public formattedStartDate: string;
  public formattedEndDate: string;
  public minDate = moment().subtract(3, 'years').startOf('day').toDate();

  public billinghistorydata: any[];
  public pageIndex = 1;
  public TotalBalance: number;
  public TotalBalanceDue: number;
  public hideColumns:boolean;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  private getInvoiceSubscription: Subscription;

  public canPayNow = false;
  public invoiceDownloadStatus = {};
  public invoiceDownloadStatusExcel = {};

  // Pagination
  public paginate: PaginationInstance;
  public tablePageSize = 25;
  public tablePageIndex = 0;
  public isDataLoading = false;
  public tableDateFormat = 'MM-dd-yyyy';
  private totalItems = 0;
  private dateFormat = 'MM/DD/YYYY';


  public sessionLanguage: string;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  public constructor(private billinghistoryservice: BillingHistoryService,
                     private utilityService: UtilityService,
                     private notificationService: NotificationService,
                     private userService: UserService,
                     private userRouteService: UserRouteService,
                     private errorHandlerService: ErrorHandlerService,
                     private router: Router,
                     private fb: UntypedFormBuilder,
                     private dialog: MatDialog,
                     private readonly appState: AppState,
                     private readonly spinnerService: SpinnerService,
                     private translateService : TranslateService
  ) {
    this.user = this.appState.user$();
    this.customer = this.appState.customer$();

    this.isUserEU = this.userService.isUserEU(this.user);
    this.isHKUser = this.userService.isHKUser(this.user);
    this.showManageCreditCard = !this.isUserEU  && !this.isHKUser;
    this.hideColumns = this.isUserEU || this.isHKUser;

    switch (this.user.CountryCode) {
      case 'DE':
      case 'FR':
      case 'IT':
        this.currencyCode = 'EUR';
        this.dateFormat = 'DD/MM/YYYY';
        this.tableDateFormat = 'dd-MM-yyyy'
        break;
      case 'GB':
        this.currencyCode = 'GBP';
        this.dateFormat = 'DD/MM/YYYY';
        this.tableDateFormat = 'dd-MM-yyyy'
        break;
      case 'HK':
        this.currencyCode = 'HKD';
        break;
      default:
        this.currencyCode = 'USD';
        break;
    }

    this.formattedStartDate = moment().subtract(730, 'days').startOf('day').format(this.dateFormat);
    this.formattedEndDate = moment().format(this.dateFormat);

    // [MV3-2002] Add condition for showing/hiding Pay Now button.
    //[MV3-5291] Hide Pay Now button in Invoice history grid for DE and GB customers
    this.canPayNow = this.userRouteService.userRoutes.ManageCreditCard || (this.user.CountryCode !== 'DE' && this.user.CountryCode !== 'GB' && this.user.CountryCode !== 'HK');

    this.TotalBalance = this.customer?.Balance || 0;
    this.TotalBalanceDue = this.customer?.TotalPastDue || 0;

    this.translateService.onLangChange.subscribe((event) => {
      this.sessionLanguage = event.lang;
      this.updateDTOptionsLanguage();
    });
  }
  
  public ngOnInit() {
   
    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('NoRecordsFound'),
    };
    this.updatePaginateConfig();
    this.initFormGroup();
    this.BindBillingHistory(this.pageIndex.toString());
  }
  public updateDTOptionsLanguage(): void {
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('NoRecordsFound'),
    };
    this.rerender();
  }
  public rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      if(this.totalItems === 0){
        dtInstance.destroy();
      }
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(null);
    });
  }
  initFormGroup() {
    this.customDateRangeFormGroup = this.fb.group({
      startDate: [this.defaultStartDate],
      endDate: [this.defaultEndDate],
    });
    this.searchFormGroup = this.fb.group({
      keyword: ['']
    });
    this.subscribeDateRangeFormGroupValues();
  }

  private subscribeDateRangeFormGroupValues() {
    this.customDateRangeFormGroup.valueChanges.subscribe(() => {
      const startDate = this.customDateRangeFormGroup.controls.startDate.value;
      const endDate = this.customDateRangeFormGroup.controls.endDate.value;
      if (dayjs(startDate).isAfter(endDate)) {
        this.customDateRangeFormGroup.controls.startDate.setValue(endDate);
      }

      if (dayjs(endDate).isBefore(startDate)) {
        this.customDateRangeFormGroup.controls.endDate.setValue(startDate);
      }

      this.formattedStartDate = moment(this.customDateRangeFormGroup.controls.startDate.value).format(this.dateFormat);
      this.formattedEndDate = moment(this.customDateRangeFormGroup.controls.endDate.value).format(this.dateFormat);
      this.BindBillingHistory(this.pageIndex.toString());
    });
  }

  public onSearchSubmit(event, form) {
    event.preventDefault();
    if (!form.keyword) {
      this.BindBillingHistory(this.pageIndex.toString());
      return;
    }
    this.searchInvoice(form.keyword);
  }

  private searchInvoice(invoiceId: string) {
    this.billinghistoryservice.searchInvoice(invoiceId).subscribe({
      next: (data) => {
        this.billinghistorydata = [];
        this.billinghistorydata.push(data);
      },
      error: (error) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          'Error Loading Invoice',
          NotificationType.ERROR);
      }
    });
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([this.getInvoiceSubscription]);
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  public openChargeCCDialog(event, item) {
    event.preventDefault();
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        InvoiceID: item.InvoiceID,
        TotalAmount: item.TotalCharge,
        ExpMonth: item.ExpMonth,
        ExpYear: item.ExpYear,
        Last4: item.Last4,
        Balance: item.Balance,
        IsPayFullBalance: true,
      },
    };
    const dialogRef: MatDialogRef<ChargeCreditCardComponent> = this.dialog.open(ChargeCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (isPaymentSuccessful) => {
        if (!isPaymentSuccessful) {
          return;
        }

        this.BindBillingHistory(this.pageIndex.toString());
      });
  }

  BindBillingHistory(pageIndex: string) {
    const startDate = moment(this.customDateRangeFormGroup.controls.startDate.value).format('YYYY-MM-DD');
    const endDate = moment(this.customDateRangeFormGroup.controls.endDate.value).format('YYYY-MM-DD');

    this.spinnerService.show();
    this.billinghistoryservice.getBillingHistory(pageIndex, this.tablePageSize.toString(), startDate, endDate)
        .pipe(
            catchError((error) => {
              this.dtTrigger.next(null);
              this.notificationService.notify(error._body || error.error?.Message, 'Error', NotificationType.ERROR);
              return of(null);
            }),
            finalize(() => {
              this.spinnerService.hide();
            }),
        )
      .subscribe(
        (response) => {
          this.tablePageIndex = +pageIndex;

          if (response) {
            this.billinghistorydata = response;
          } else {
            this.billinghistorydata = [];
          }

          if (response && response.length) {
            this.totalItems = +response[0].TotalInvoices;
          }

          this.dtTrigger.next(null);
          this.updatePaginateConfig();
        });
  }

  public async moveToManageCC(): Promise<void> {
    await this.router.navigate(['/manage-credit-card']);
  }

  ShowMore() {
    this.pageIndex = this.pageIndex + 1;
    this.BindBillingHistory(this.pageIndex.toString());
  }

  printWindow(invoiceid: string) {
    window.open('https://parcelpro.com/invoice/print?iid=' + invoiceid + '&sessionid=f5792752-0a3c-4188-b7b7-ec00ef40139e', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }

  public downloadInvoice(event, invoiceId: string) {
    event.preventDefault();

    this.invoiceDownloadStatus[invoiceId] = true;
    this.billinghistoryservice.getInvoice(invoiceId)
      .subscribe({
        next: (invoice) => {
          const base64Pdf = `data:application/pdf;base64,${invoice.InvoiceEncodedByte}`;
          const blob = this.getBlob(base64Pdf);
          this.invoiceDownloadStatus[invoiceId] = false;
          FileSaver.saveAs(blob, `invoice-${invoiceId}.pdf`);
        },
        error: (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Creating Invoice',
            NotificationType.ERROR);
      
          this.invoiceDownloadStatus[invoiceId] = false;
        },
      });
  }

  public downloadInvoiceExcel(event, invoiceId: string) {
    event.preventDefault();    
    this.invoiceDownloadStatusExcel[invoiceId] = true;

    this.billinghistoryservice.getBytesForInvoiceExcel(invoiceId.trim())
    .subscribe(
      (res) => {
        let exportedData = JSON.parse(JSON.stringify(res));
        this.exportExcel(exportedData);
        this.invoiceDownloadStatusExcel[invoiceId] = false;
      },
      (err) => {
        this.notificationService.notify(
                this.errorHandlerService.getHttpErrorMessage(err),
                'Unable to download file. PLease try again later',
                NotificationType.ERROR);
                this.invoiceDownloadStatusExcel[invoiceId] = false;
      },
    );
  }

  public isInvoiceDownloading(invoiceId: string) {
    return !!this.invoiceDownloadStatus[invoiceId];
  }

  public isInvoiceDownloadingExcel(invoiceId: string) {
    return !!this.invoiceDownloadStatusExcel[invoiceId];
  }

  private getBlob(base64String: string) {
    if (!base64String) {
      return;
    }

    const contentType = base64String.split(';')[0].split(':')[1];
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = [];

    for (let i = 0; i < byteCharacters.length; i = i + 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  public exportExcel(exportedData:any) {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Invoice Details');
    const ws = XLSX.utils.json_to_sheet(
      exportedData,
      {
        header: [
          'Customer ID',
          'Invoice Date',
          'Ship Date',
          'Customer Name',
          'Invoice ID',
          'Days Of Credit',
          'Total Charge',
          'Balance Due',
          'Tracking Number',
          'ShipmentType',
          'Reference 1',
          'Reference 2',
          'Weight',
          'Region',
          'Coverage',
          'Pieces',
          'Description',
          'Accessorial Fee',
          'Base Price',
          'Sub Total',
          'Carrier Service',
          'Ship from Company Name',
          'Ship from Address 1',
          'Ship from City',
          'Ship from Zip',
          'Ship from Country',
          'Ship to Company Name',
          'Ship to Address1',
          'Ship to City',
          'Ship to Zip',
          'Ship to Country',
        ],
      });
    wb.Sheets['Invoice Details'] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), 'invoice-'+ exportedData[0]["Invoice ID"] + ".xlsx");
  }

  public s2ab(s) {
    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf);  // create uint8array as viewer
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    } // convert to octet
    return buf;
  }

  public onPageChanged(pageNumber: number) {
    this.BindBillingHistory(pageNumber.toString());
  }
}
