<div class="billing-history white-content-page">
    <upsc-page-header [pageTitle]="'Invoices'| translate"></upsc-page-header>
    <div class="ups-content-block content-actions">
        <div class="left-actions">
            <div class="notice">
                <span>
                    {{'TotalBalance'|translate}}: <strong>{{TotalBalance | userGBDELocaleNumber:user}}
                    {{currencyCode}}</strong>
                    | {{'BalancePastDue'|translate}}: <strong> {{TotalBalanceDue | userGBDELocaleNumber:user}}
                    {{currencyCode}}</strong>
                </span>
            </div>
        </div>
        <div class="right-actions">

        </div>
    </div>
    <div class="ups-content-block content-actions">
        <div class=" left-actions">
            <div class=" left-actions">
                <upsc-date-range-selector
                    [customDateRangeFormGroup]="customDateRangeFormGroup"
                    [formattedStartDate]="formattedStartDate"
                    [formattedEndDate]="formattedEndDate">
                </upsc-date-range-selector>
            </div>
        </div>
        <div class="right-actions">
            <form (ngSubmit)="onSearchSubmit($event, searchFormGroup.value)" [formGroup]="searchFormGroup">
                <mat-form-field>
                    <mat-label>{{'SearchInvoiceID' | translate}}</mat-label>
                    <input formControlName="keyword" matInput type="text">
                    <mat-icon (click)="onSearchSubmit($event, searchFormGroup.value)" class="search" matSuffix>search
                    </mat-icon>
                </mat-form-field>
            </form>
            <button (click)="moveToManageCC()"
                    *ngIf="showManageCreditCard"
                    class="action"
                    mat-raised-button
                    type="button">
                {{'ManageCreditCard'|translate}}
            </button>
        </div>
    </div>
    <div [class.responsive-table]="dtOptions.responsive" class="table-wrapper">
        <table [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" [hidden]="!billinghistorydata" class="stripe" datatable>
            <thead>
            <tr>
                <th class="all trigger-column hide-me"></th>
                <th class="text-center all" data-priority="3">
                    {{'pdf'|translate}}
                </th>
                <th class="text-center all" data-priority="3">
                    {{'excel'|translate}}
                </th>
                <th class="text-center all"></th>
                <th class="text-center" data-priority="2">
                    {{'Status'|translate}}
                </th>
                <th class="text-center">
                    {{'Date'|translate}}
                </th>
                <th class="text-center all">
                    {{'InvoiceId'|translate}}
                </th>
                <th class="text-center" data-priority="4">
                    {{'TotalCharge'|translate}}
                </th>
                <th class="text-center" data-priority="1">
                    {{'Balance'|translate}}
                </th>
                <th [hidden]="hideColumns" class="text-center" data-priority="6">
                    {{'PointsIssued'|translate}}
                </th>
                <th [hidden]="hideColumns" class="text-center" data-priority="7">
                    {{'PointsRedeemed'|translate}}
                </th>
                <th [hidden]="hideColumns" class="text-center" data-priority="5">
                    {{'PointsExpiredBy'|translate}}
                </th>
            </tr>
            </thead>
            <tbody #rowcount>
            <tr *ngFor="let item of billinghistorydata | paginate:paginate">
                <td class="trigger-cell hide-me"></td>
                <td class="text-center">
                    <div class="actions">
                        <div class="spinner-container">
                            <a [href]="'#'" *ngIf="!isInvoiceDownloading(item.InvoiceID)" class="alternative-button" 
                               (click)="downloadInvoice($event, item.InvoiceID)">
                               <mat-icon>get_app</mat-icon>
                             </a>
                            <mat-spinner diameter="24" *ngIf="isInvoiceDownloading(item.InvoiceID)"></mat-spinner>
                        </div>
                        
                        <a (click)="printWindow(item.InvoiceID)" class="hide-me" href="javascript:;" mat-raised-button
                           role="button">{{'View'|translate}}</a>
                    </div>
                </td>
                <td class="text-center">
                    <div class="actions">
                        <div class="spinner-container">
                             <a [href]="'#'" *ngIf="!isInvoiceDownloadingExcel(item.InvoiceID)" class="alternative-button" 
                               (click)="downloadInvoiceExcel($event, item.InvoiceID)">
                               <img src="assets/images/Excel.png" class="image" width="35" height="30" role="button"/>
                             </a>
                            <mat-spinner diameter="24" *ngIf="isInvoiceDownloadingExcel(item.InvoiceID)"></mat-spinner>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="actions">
                        <a (click)="openChargeCCDialog($event, item)" *ngIf="item.Balance > '0' && canPayNow" href="javascript:;"
                           mat-raised-button
                           role="button">{{'PayNow'|translate}}</a>
                    </div>
                </td>
                <td class="text-center">
                        <span *ngIf="item.Balance === '0'" class="payment-status" style="color:#72BB53">
                            <button class="btn btn-greenbullet" type="button"></button>
                            {{'Paid'|translate}}
                        </span>
                    <span *ngIf="item.Balance !== '0' && item.IsPastDue" class="payment-status" style="color:#FF5D55">
                            <button class="btn btn-redbullet" type="button"></button>
                        {{'Due'|translate}}
                        </span>
                </td>
                <td class="text-center"> {{ item.InvoiceDate | date : this.tableDateFormat }}</td>
                <td class="text-center"> {{ item.InvoiceID }}</td>
                <td class="text-center"> {{ item.TotalCharge | number : '1.2-2' }} {{currencyCode}}</td>
                <td class="text-center"> {{ item.Balance | number : '1.2-2' }} {{currencyCode}}</td>
                <td [hidden]="hideColumns" class="text-center">{{ item.PointsIssued }}</td>
                <td [hidden]="hideColumns" class="text-center">{{ item.PointsRedeemed }}</td>
                <td [hidden]="hideColumns" class="text-center">{{ item.PointsExpired | date : this.tableDateFormat }}</td>
            </tr>
            </tbody>
        </table>

        <pagination-controls (pageChange)="onPageChanged($event)" [autoHide]="true" [directionLinks]="true" [nextLabel]="''"
                             [previousLabel]="''"></pagination-controls>
        <upsc-element-blocker *ngIf="isDataLoading"></upsc-element-blocker>
    </div>
</div>
