/* src/app/history/bir/bir.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.bir .table-wrapper {
  position: relative;
}
.grid-head,
.grid-rows {
  font-size: 11px;
}
.instruction {
  font-size: 13px;
  color: #573616;
}
.bir-information {
  color: #573616;
  padding-left: 2.5rem;
}
.bir-information,
.tracking-information {
  margin-bottom: 40px;
}
.bir-footer {
  margin-top: 10px;
  margin-left: 3em;
}
.txtbox {
  border-bottom: 0px !important;
  background-color: inherit;
}
.ship-date {
  color: #595555;
}
.ship-date span {
  margin-right: 5px;
}
.ship-date .mat-icon {
  vertical-align: text-top;
}
.ship-date:hover {
  text-decoration: none;
}
.bir {
  color: #4a4a4a;
}
.bir.saved {
  color: #00857d;
}
.bir.void {
  color: #DF2901;
}
.dashboard-link {
  width: auto;
  display: inline-flex !important;
  text-decoration: none;
}
.dashboard-link:hover {
  text-decoration: none;
}
.material-icons {
  font-size: 18px;
}
@media screen and (min-width: 48rem) {
  .ups-page-header .page-title {
    margin-left: 0;
  }
}
/*# sourceMappingURL=bir.component.css.map */
