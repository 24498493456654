/* src/app/history/billing-history/billing-history.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.billing-history .mat-mdc-raised-button {
  padding: 0 1rem;
}
.billing-history .content-actions .date-input .formatted-date {
  color: #0f0f0f;
  font-weight: 600;
}
.billing-history .content-actions .mat-mdc-form-field {
}
@media only screen and (max-width: 21.5rem) {
  .billing-history .content-actions .mat-mdc-form-field {
    width: 100%;
  }
}
.billing-history .content-actions .mat-mdc-form-field .mat-form-field-underline {
  height: 2px;
}
.billing-history .content-actions .search {
  color: #979797;
  cursor: pointer;
}
@media screen and (min-width: 48rem) {
  .billing-history .content-actions .right-actions {
    align-items: flex-start;
  }
}
.billing-history .content-actions .right-actions .action {
  white-space: nowrap;
}
.billing-history table.dataTable tbody th,
.billing-history table.dataTable tbody td {
  padding: 8px 4px;
}
.billing-history .spinner-container .mat-mdc-raised-button + upsc-element-blocker {
  padding: 0 0.5231em;
}
.billing-history .spinner-container .mat-mdc-raised-button + upsc-element-blocker ::ng-deep .mat-mdc-progress-spinner {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.billing-history .spinner-container .mat-mdc-raised-button + upsc-element-blocker ::ng-deep .mat-mdc-progress-spinner svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.payment-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.1em;
}
.btn {
  border: none;
}
/*# sourceMappingURL=billing-history.component.css.map */
