import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IClaim } from '../models/claim.interface';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { Claims } from '../models/claims.model';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class ClaimService {
  constructor( private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {
  }

  /**
   * Gets claim contact.
   *
   * @returns {Observable<IClaim>}
   */
  public getClaimContact(): Observable<IClaim> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getClaimContact'))
      .toString();
    return this.httpClientService.get<IClaim>(endpoint);
  }


  /**
   * Saves a claim.
   *
   * @param {IClaim} claim
   * @returns {Observable<any>}
   */
  public submitClaim(claim: Claims): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('saveClaimPackageInvestigation'))
      .toString();
    return this.httpClientService.post<any>(endpoint, claim);
  }
}
