/* src/app/history/shipment-history/shipment-history.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.shipment-history {
  font-size: 1rem;
}
.shipment-history .content-actions input.shipment-history-date-range {
  font-size: 0.875em;
}
.shipment-history .content-actions .right-actions button.export {
  width: auto;
}
.shipment-history .table-wrapper {
  position: relative;
}
@media screen and (min-width: 48rem) {
  .shipment-history .table-wrapper {
    padding: 0 2rem;
  }
}
.shipment-history .table-wrapper ::ng-deep .dataTables_wrapper {
  padding: 0;
}
.shipment-history ::ng-deep .ngx-pagination {
  padding: 0;
}
.shipment-history .search {
  cursor: pointer;
}
.content-actions .left-actions,
.content-actions .right-actions {
  align-items: baseline;
  column-gap: 1rem;
}
.content-actions .left-actions form,
.content-actions .right-actions form {
  width: 100%;
}
.content-actions .left-actions form .mat-mdc-form-field,
.content-actions .right-actions form .mat-mdc-form-field {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .content-actions .left-actions form .mat-mdc-form-field,
  .content-actions .right-actions form .mat-mdc-form-field {
    width: 13rem;
  }
}
.content-actions .left-actions .action {
  margin-right: 0;
}
.content-actions .right-actions .action {
  margin-left: 0;
}
.content-actions form.action {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  column-gap: 1rem;
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .content-actions form.action {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }
}
.popup-container {
  position: relative;
}
.popup-box {
  display: none;
  position: absolute;
  top: 1rem;
  left: -6rem;
  background: #F2F1EF;
  width: 100%;
  min-width: 270px;
  height: auto;
  min-height: 90px;
  padding: 10px;
  color: #121212;
  border-radius: 4px;
  text-align: center;
  z-index: 9998;
  font-size: 14px;
  white-space: wrap;
}
.triangleDown {
  width: 0;
  height: 0;
  z-index: 9998;
  border-top: 15px solid #F2F1EF;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  position: absolute;
  top: 5.6rem;
  left: 7.1rem;
}
.popup-container:hover .popup-box {
  display: block;
}
/*# sourceMappingURL=shipment-history.component.css.map */
