/* src/app/history/report-history/reports.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.reports {
  font-size: 1rem;
}
.reports table {
  font-size: 1rem;
}
.right-actions {
  align-items: baseline;
}
::ng-deep .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
::ng-deep .status.ok::before {
  background-color: #64a70b;
}
::ng-deep .status.void::before,
::ng-deep .status.voided::before {
  background-color: #DF2901;
}
.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
/*# sourceMappingURL=reports.component.css.map */
