import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppStateService } from '../../../app-state.service';
import { AppState } from '../../../app.state';
import { ThrottleActionService } from '../../../shared/services/throttle-action.service';
import { CCTransaction } from '../models/credit-card-transaction.model';
import { CreditCardsService } from '../../../billing/services/credit-cards.service';
import { CreditCard } from '../../../billing/models/credit-card.model';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import printJS from 'print-js';

@Component({
  selector: 'upsc-charge-credit-card',
  templateUrl: './charge-credit-card.component.html',
  styleUrls: ['./charge-credit-card.component.scss'],
})
export class ChargeCreditCardComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public CCData: any;
  public CCTrans: CCTransaction = new CCTransaction();
  public PrimaryCard: CreditCard;
  public isPaymentInProgress = false;
  public PaymentSuccessful = false;
  public CustomerID: string;
  public AuthNo: string = 'See Payment History';

  private ngDestroyed$ = new Subject<void>();

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                     private formBuild: UntypedFormBuilder,
                     private creditCardsService: CreditCardsService,
                     private utilityService: UtilityService,
                     private errorHandlerService: ErrorHandlerService,
                     private notificationService: NotificationService,
                     public readonly throttleActionService: ThrottleActionService,
                     private readonly appState: AppState,
                     private readonly appStateService: AppStateService,
  ) {
    this.CCData = data;
    this.CCTrans.TotalAmount = this.CCData.Balance;
    this.CCData.TotalAmount = this.utilityService.formatMoney(this.CCData.TotalAmount, 'USD');
  }

  public ngOnInit() {
    this.getPrimaryCC();
    this.formGroup = this.formBuild.group({
      ccv: [
        '', Validators.compose([
          Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^[0-9]{3,4}$'),
        ]),
      ],
    });
  }

  public ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  getPrimaryCC() {
    this.creditCardsService.getPrimaryCreditCard().subscribe(
      (res) => {
        this.filterPrimaryRes(res);
      },
    );
  }

  public submitPayment() {
    // console.log(this.CCData);
    // console.log('ccv', this.formGroup.controls.ccv.value);
    this.CCTrans.InvoiceID = this.CCData?.InvoiceID;
    this.CCTrans.IsPayFullBalance = this.CCData?.IsPayFullBalance;
    this.CCTrans.CCV = this.formGroup?.controls?.ccv?.value;

    this.isPaymentInProgress = true;
    this.creditCardsService.chargeCreditCard(this.CCTrans)
        .pipe(
            takeUntil(this.ngDestroyed$),
            catchError((err) => {
              this.filterErr(err);
              console.error(err);
              return of(null);
            }),
            filter(res => !!res),
            switchMap((res) => {
              this.filterRes(res);
              return this.appStateService.refreshAppState();
            }),
            finalize(() => {
              this.isPaymentInProgress = false;
            }),
        )
        .subscribe();
  }

  filterPrimaryRes(res: any) {
    this.PrimaryCard = res;
    this.CustomerID = this.appState.user$()?.CustomerId;
  }

  filterRes(res: any) {
   // this.AuthNo = res.statusText.match(/\d/g).join('');
    this.PaymentSuccessful = true;
  }

  filterErr(err: any) {
    this.notificationService.notify(this.errorHandlerService.getHttpErrorMessage(err), 'Payment Failed', NotificationType.ERROR);
    this.PaymentSuccessful = false;
  }

  public printReceipt(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    printJS({ printable: 'billing-receipt', type: 'html', showModal: true, targetStyles: ['*'] });
  }
}
